import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['name', 'ppEnv']
  static values =  { format: String, name: String, ppEnv: String }

  initialize() {
    this.formatValue = this.formatVal()
    this.ppEnvValue = this.ppEnvTarget.dataset.env
    let waitTime;
    if (typeof(window.paypal) == 'undefined') {
      waitTime = 500
    } else {
      waitTime = 0
    }
    this.delayInit(waitTime)
  }

  delayInit(waitTime) {
    const self = this
    setTimeout(function() {
      if (typeof(window.paypal) == 'undefined') {
        delayInit(500)
        return
      }
      self.initPayPal()
      // show total price on page
      self.formatChange()

      // show field errors and enable/disable buttons
      self.checkFormValid()
    }, waitTime)
  }

  performInit() {
    this.initPayPal()
    // show total price on page
    this.formatChange()

    // show field errors and enable/disable buttons
    this.checkFormValid()
  }

  initPayPal() {
    window.call_sign = function() {
      return document.getElementById('order_call_sign').value
    }
    window.order_name = function() {
      return document.getElementById('order_name').value
    }
    window.certificate_format = function() {
      if (document.getElementById('order_certificate_format_paper').checked) {
        return 'paper'
      } else if (document.getElementById('order_certificate_format_pdf').checked) {
        return 'pdf'
      }
    }
    window.certificate_type = function() {
      return document.getElementById('order_certificate_type').value
    }
    window.showAlert = function (text) {
      const alertEle = document.getElementById('form-alert')
      alertEle.innerHTML = '<div class="row my-2 px-1">' +
                             '<div class= "alert alert-danger alert-dismissible fade show">' +
                             text +
                             '<button class="btn-close" type="button" "data-bs-dismiss"="alert" "aria-label"="close">' +
                             '</div>' +
                             '</div>'

    }
    window.paypal.Buttons({
      env: this.ppEnvValue,
      onInit: function(data, actions)  {
        actions.disable()
        const checkEle = document.getElementById('order_form_check')
        checkEle.addEventListener('change', function(event) {
          // Enable or disable the button when it is checked or unchecked
          if (event.target.checked)  {
            actions.enable()
          } else  {
            actions.disable()
          }
        })
      },
      onClick: function(data, actions) {
        // console.log('on click event fired')
      },
      createOrder: async () => {
        const params = {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            order: {
              call_sign: window.call_sign(),
              certificate_type: window.certificate_type(),
              certificate_format: window.certificate_format(),
              name: window.order_name()
            }
          })
        }
        const response = await fetch('/orders', params)
        const responseData = await response.json();
        if (responseData.errors) {
          window.showAlert(responseData.errors)
          return null
          // cancel?
        }
        return responseData.token
      },
      onApprove: async (data) => {
        const response = await fetch('/orders/capture', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ order_id: data.orderID })
        });
        const responseData = await response.json();
        if (responseData.status === 200) {
          window.location.reload()
        } else {
          window.location.reload()
        }
      },
      onError: function(info) {
        return false
      }
    }).render('#paypal-button-container');
  }

  formatChange(_event) {
    this.formatValue = this.formatVal()
    let totalCost;
    if (this.formatValue == 'pdf') {
      totalCost = '$5.00'
    } else {
      totalCost = '$10'
    }
    document.getElementById('order_total').innerText = totalCost
  }

  formatVal() {
    return document.querySelector('input[name="order[certificate_format]"]:checked').value
  }

  nameEle() {
    return document.querySelector('input[name="order[name]"]')
  }

  checkFormValid() {
    const ppButtons = document.getElementById('paypal-button-container')
    if (this.checkNameValid()) {
      document.getElementById('order_form_check').checked = true
      document.getElementById('order_form_check').dispatchEvent(new Event('change'))
      return true
    } else {
      document.getElementById('order_form_check').checked = false
      document.getElementById('order_form_check').dispatchEvent(new Event('change'))
      return false
    }
  }

  nameChange(_event) {
    this.nameValue = this.nameVal()
    this.checkFormValid()
  }

  checkNameValid() {
    let ele = this.nameEle()
    if (this.nameValid()) {
      ele.classList.add('is-valid')
      ele.classList.remove('is-invalid')
      return true
    } else {
      ele.classList.add('is-invalid')
      ele.classList.remove('is-valid')
      return false
    }
  }

  nameValid() {
    return this.nameValue.length >= 3
  }

  nameVal() {
    return this.nameTarget.value
  }
}
