import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const selectors = ['qso_band', 'qso_mode', 'qso_call']
    selectors.forEach(function (selector) {
      const ele = document.getElementById(selector)
      const val = ele.value
      if (!val) {
        triggerFocus(ele)
        return
      }
    })
    setTimeout(function () {
      document.querySelectorAll('#qsos tr.bg-info').forEach(function (ele) {
        ele.classList.remove('bg-info')
      })
    }, 3000)
    super.connect()
  }
}

function triggerFocus(element) {
  var eventType = "onfocusin" in element ? "focusin" : "focus",
    bubbles = "onfocusin" in element,
    event;

  if ("createEvent" in document) {
    event = document.createEvent("Event");
    event.initEvent(eventType, bubbles, true);
  }
  else if ("Event" in window) {
    event = new Event(eventType, { bubbles: bubbles, cancelable: true });
  }

  element.focus();
  element.dispatchEvent(event);
}
