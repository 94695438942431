import Flatpickr from "stimulus-flatpickr"
import "flatpickr/dist/themes/airbnb.css"

export default class extends Flatpickr {
  connect() {
    this.config = {
      dateFormat: 'Y-m-d',
      enableTime: false,
      monthSelectorType: 'static',
      allowInput: true,
    }
    super.connect()
  }
}
