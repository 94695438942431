import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["band", "bandId", "date", "mode", "modeId", "noResults", "results", "state", "stateId"]
  static values =  { band: String, date: String, mode: String, state: String }

  initialize() {
    this.dateValue = this.dateVal()
    this.bandValue = this.bandVal()
    this.modeValue = this.modeVal()
    this.stateValue = this.stateVal()
  }

  connect() {
    // console.log('hunters connect')
  }

  bandChange(event) {
    let select = event.srcElement
    let selected = select.options[select.selectedIndex]
    const modes = document.getElementById('activation_mode_id')
    const modeOpts = [...modes.options]

    modeOpts.forEach(function(option) {
      if (selected.text == '2 meter VHF') {
        if (option.text == 'FM Phone') {
          option.removeAttribute('disabled')
          // option.setAttribute('selected', true)
          // } else {
          // option.removeAttribute('selected')
          // option.setAttribute('disabled', true)
        }
      } else {
        if (option.text == 'FM Phone') {
          option.removeAttribute('selected')
          option.setAttribute('disabled', true)
        } else {
          option.removeAttribute('disabled')
        }
      }
    })
  }

  // apply filters when page is reloaded
  resultsTargetConnected(element) {
    this.applyFilters()
  }

  bandChanged() {
    // console.log('band changed')
    this.bandValue = this.bandVal()
    this.applyFilters()
  }

  dateChanged() {
    // console.log('date changed')
    this.dateValue = this.dateVal()
    const urlParams = new URLSearchParams(window.location.search)
    if (this.bandValue != null) {
      urlParams.set('band', this.bandValue)
    }
    urlParams.set('date', this.dateValue)
    if (this.modeValue != null) {
      urlParams.set('mode', this.modeValue)
    }
    if (this.stateValue != null) {
      urlParams.set('state', this.stateValue)
    }
    window.location.search = urlParams;
  }

  modeChanged() {
    // console.log('mode changed')
    this.modeValue = this.modeVal()
    this.applyFilters()
  }

  stateChanged() {
    // console.log('state changed')
    this.stateValue = this.stateVal()
    this.applyFilters()
  }

  timezoneChanged(event) {
    let timezone = document.querySelector('input[name=timezone]:checked').value;
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('timezone', timezone);
    if (this.bandValue != null) {
      urlParams.set('band', this.bandValue)
    }
    urlParams.set('date', this.dateValue)
    if (this.modeValue != null) {
      urlParams.set('mode', this.modeValue)
    }
    if (this.stateValue != null) {
      urlParams.set('state', this.stateValue)
    }
    window.location.search = urlParams;
  }

  applyFilters() {
    const elements = [...document.querySelectorAll('table#activations tbody tr')]
    const self = this
    const bandMatches = elements.filter(function(ele) {
      return ['', ele.dataset.band].includes(self.bandValue)
    })
    const modeMatches = elements.filter(function(ele) {
      return ['', ele.dataset.mode].includes(self.modeValue)
    })
    const stateMatches = elements.filter(function(ele) {
      return ['', ele.dataset.state].includes(self.stateValue)
    })

    let matches = []
    elements.forEach(function(ele) {
      if (bandMatches.includes(ele) && modeMatches.includes(ele) && stateMatches.includes(ele)) {
        matches.push(ele)
        ele.classList.remove('d-none')
      } else {
        ele.classList.add('d-none')
      }
    })

    // console.log(`matches: ${matches.length}`)
    // console.log(matches)
    if (matches.length < 2) {
      this.showNoResults()
    } else {
      this.hideNoResults()
    }
  }

  hideNoResults() {
    this.noResultsTarget.classList.add('d-none')
  }

  showNoResults() {
    this.noResultsTarget.classList.remove('d-none')
  }

  bandVal() {
    return this.bandTarget.value
  }

  dateVal() {
    return this.dateTarget.value
  }

  modeVal() {
    return this.modeTarget.value
  }

  stateVal() {
    return this.stateTarget.value
  }
}
