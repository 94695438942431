import Flatpickr from "stimulus-flatpickr"
import "flatpickr/dist/themes/airbnb.css"

export default class extends Flatpickr {
  connect() {
    this.config = {
      allowInput: true,
      enableSeconds: true,
      enableTime: true,
      minuteIncrement: 1,
      noCalendar: true,
      time_24hr: true
    }
    super.connect()
  }
}
