import { Controller } from "@hotwired/stimulus"
import dateFormat from "dateformat"

export default class extends Controller {
  static targets = ["band", "mode"]

  startUtcTimeDisplay() {
    const now = new Date()
    const t_ele = document.getElementById('utc_time')
    if (!!t_ele) {
      t_ele.innerHTML = dateFormat(now, 'UTC:HH:MM:ss')
    } else {
      // console.log('no t_ele')
    }
    const d_ele = document.getElementById('utc_date')
    if (!!d_ele) {
      d_ele.innerHTML = dateFormat(now, 'UTC:yyyy-mm-dd')
    } else {
      // console.log('no d_ele')
    }
  }

  connect() {
    this.startUtcTimeDisplay()
    setInterval(this.startUtcTimeDisplay, 900)
  }

  disconnect() {
    clearInterval(this.startUtcTimeDisplay)
  }

  bandBtnClick(event) {
    const element = event.srcElement
    const elements = [...document.querySelectorAll('#band_btn_group button')]
    elements.forEach(function (ele) {
      ele.classList.remove('active')
      ele.classList.remove('btn-outline-danger')
      ele.classList.add('btn-outline-primary')
      ele.removeAttribute('checked')
    })
    element.classList.add('active')
    element.setAttribute('checked', true)
    document.getElementById('qso_band').value = element.value
    let fmBtn = document.querySelector("[data-id='mode_btn_fm']")
    let ssbBtn = document.querySelector("[data-id='mode_btn_ssb']")

    if (element.value == '2M') {
      if (ssbBtn.classList.contains('active')) {
        ssbBtn.classList.remove('active')
        ssbBtn.removeAttribute('checked')
        fmBtn.classList.add('active')
        fmBtn.setAttribute('checked', true)
      }
      fmBtn.removeAttribute('disabled')
    } else {
      if (fmBtn.classList.contains('active')) {
        fmBtn.classList.remove('active')
        fmBtn.removeAttribute('checked')
        ssbBtn.classList.add('active')
        ssbBtn.setAttribute('checked', true)
      }
      fmBtn.setAttribute('disabled', true)
    }
  }

  modeBtnClick(event) {
    let element = event.srcElement
    const elements = [...document.querySelectorAll('#mode_btn_group button')]
    elements.forEach(function (ele) {
      ele.classList.remove('active')
      ele.classList.remove('btn-outline-danger')
      ele.classList.add('btn-outline-primary')
      ele.removeAttribute('checked')
    })
    element.classList.add('active')
    element.setAttribute('checked', true)
    document.getElementById('qso_mode').value = element.value
    const rst = document.getElementById('qso_rst')
    if (element.value == 'CW') {
      if (rst.value == '59') {
        rst.value = '599'
      }
    } else {
      if (rst.value == '599') {
        rst.value = '59'
      }
    }
  }

  realTimeChange(event) {
    const checked = event.srcElement.checked
    if (checked) {
      document.getElementById('real_time_no').classList.add('d-none')
    } else {
      document.getElementById('real_time_no').classList.remove('d-none')
    }
  }
}
