import Flatpickr from "stimulus-flatpickr"
import dateFormat from "dateformat"
import "flatpickr/dist/themes/airbnb.css"

export default class extends Flatpickr {
  connect() {
    this.config = {
      altFormat: 'D, M d h:i K',
      altInput: true,
      dateFormat: 'Z',
      enableTime: true,
      minuteIncrement: 15,
      monthSelectorType: 'static'
    }
    super.connect()
  }

  startDateChanged(event) {
    const val = event.srcElement.value
    if (typeof(val) == 'undefined') {
      return false
    }
    const d = new Date(val)
    const ends = document.querySelector('#operation_ends_at')._flatpickr
    const newTime = d.setHours(d.getHours() + 2)
    ends.setDate(dateFormat(newTime, 'ddd, mmm d h:MM TT'), true, 'D, M j h:i K')
  }
}