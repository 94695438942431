import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["activator", "band", "bandId", "date", "mode", "modeId", "noResults", "results", "topButtons"]
  static values =  { activator: String, band: String, date: String, mode: String }

  initialize() {
    this.activatorValue = ''
    this.dateValue = ''
    this.bandValue = ''
    this.modeValue = ''
    // console.log('initialize')
  }

  connect() {
    // console.log('connect')
  }

  bandChange(event) {
    let select = event.srcElement
    let selected = select.options[select.selectedIndex]
    const modes = document.getElementById('operation_mode_id')
    const modeOpts = [...modes.options]

    modeOpts.forEach(function(option) {
      if (selected.text == '2 meter VHF') {
        if (option.text == 'FM Phone') {
          option.removeAttribute('disabled')
          option.setAttribute('selected', true)
        }
      } else {
        if (option.text == 'FM Phone') {
          option.removeAttribute('selected')
          option.setAttribute('disabled', true)
        }
      }
    })
  }

  // apply filters when page is reloaded
  resultsTargetConnected(element) {
    this.applyFilters()
  }

  activatorChanged(event) {
    this.activatorValue = this.activatorVal()
    this.applyFilters()
  }


  bandChanged() {
    this.bandValue = this.bandVal()
    this.applyFilters()
  }

  dateChanged() {
    this.dateValue = this.dateVal()
    this.applyFilters()
  }

  modeChanged() {
    this.modeValue = this.modeVal()
    this.applyFilters()
  }

  applyFilters() {
    const elements = [...document.querySelectorAll('table#operations tbody tr')]
    const self = this
    const activatorMatches = elements.filter(function(ele) {
      return ['', ele.dataset.activator].includes(self.activatorValue)
    })
    const bandMatches = elements.filter(function(ele) {
      return ['', ele.dataset.band].includes(self.bandValue)
    })
    const dateMatches = elements.filter(function(ele) {
      return ['', ele.dataset.date].includes(self.dateValue)
    })
    const modeMatches = elements.filter(function(ele) {
      return ['', ele.dataset.mode].includes(self.modeValue)
    })
    const matches = []
    elements.forEach(function(ele) {
      if (activatorMatches.includes(ele) && bandMatches.includes(ele) && dateMatches.includes(ele) && modeMatches.includes(ele)) {
        matches.push(ele)
        ele.classList.remove('d-none')
      } else {
        ele.classList.add('d-none')
      }
    })
    if (matches.length < 1) {
      // console.log(`matches: ${matches.length}`)
      this.showNoResults()
      this.hideTopButtons()
    } else {
      this.hideNoResults()
      this.showTopButtons()
    }
  }

  hideNoResults() {
    this.noResultsTarget.classList.add('d-none')
  }

  hideTopButtons() {
    this.topButtonsTarget.classList.add('d-none')
  }

  showNoResults() {
    this.noResultsTarget.classList.remove('d-none')
  }

  showTopButtons() {
    this.topButtonsTarget.classList.remove('d-none')
  }

  activatorVal() {
    return this.activatorTarget.value
  }

  bandVal() {
    return this.bandTarget.value
  }

  dateVal() {
    return this.dateTarget.value
  }

  modeVal() {
    return this.modeTarget.value
  }
}
