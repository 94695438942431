import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["band", "mode"]

  connect() {
  }

  bandBtnClick(event) {
    let element = event.srcElement
    const elements = [...document.querySelectorAll('#band_btn_group button')]
    elements.forEach(function (ele) {
      ele.classList.remove('active')
      ele.removeAttribute('checked')
    })
    element.classList.add('active')
    element.setAttribute('checked', true)
    document.getElementById('qso_band').value = element.value
    let fmBtn = document.querySelector("[data-id='mode_btn_fm']")
    let ssbBtn = document.querySelector("[data-id='mode_btn_ssb']")

    if (element.value == '2M') {
      if (ssbBtn.classList.contains('active')) {
        ssbBtn.classList.remove('active')
        ssbBtn.removeAttribute('checked')
        fmBtn.classList.add('active')
        fmBtn.setAttribute('checked', true)
      }
      fmBtn.removeAttribute('disabled')
    } else {
      if (fmBtn.classList.contains('active')) {
        fmBtn.classList.remove('active')
        fmBtn.removeAttribute('checked')
        ssbBtn.classList.add('active')
        ssbBtn.setAttribute('checked', true)
      }
      fmBtn.setAttribute('disabled', true)
    }
  }

  modeBtnClick(event) {
    let element = event.srcElement
    const elements = [...document.querySelectorAll('#mode_btn_group button')]
    elements.forEach(function (ele) {
      ele.classList.remove('active')
      ele.removeAttribute('checked')
    })
    element.classList.add('active')
    element.setAttribute('checked', true)
    document.getElementById('qso_mode').value = element.value
  }
}
